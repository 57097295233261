type Accordion = {
    label: Element;
    content: Element;
    multiToggle: MultiToggle | null;
};

type MultiToggle = {
    element: Element;
    defaultIndex: number;
};

export class FilterSidebar {
    private siteHeader: HTMLElement;
    private productSearchFilter: HTMLElement;
    private toggleButtonOpen: HTMLElement;
    private paymentOptionToggles: NodeListOf<HTMLElement>;
    private financeBtnToggle: HTMLElement;
    private paymentFilters: NodeListOf<HTMLElement>;
    private accordions: Accordion[] = [];

    constructor(private filterSidebar: Element) {
        this.siteHeader = document.getElementById('header') as HTMLElement;

        this.productSearchFilter = document.querySelector(
            '.gw-product-search-results--stock'
        )!;

        this.toggleButtonOpen = document.querySelector(
            '.gw-filter-sidebar__toggle'
        )!;

        this.paymentOptionToggles = this.filterSidebar.querySelectorAll(
            '.gw-filter-sidebar__accordion-pricing .gw-button--multi-toggle .gw-form__field'
        );

        this.financeBtnToggle = this.filterSidebar.querySelector(
            '#filter-sidebar-button-finance'
        )!;

        this.paymentFilters = this.filterSidebar.querySelectorAll(
            '.gw-filter-sidebar__payment-filter'
        );

        this.init();
    }

    public static start(): any {
        const filterSidebar = document.querySelector('.gw-filter-sidebar');
        if (filterSidebar) return new FilterSidebar(filterSidebar);
    }

    private init() {
        this.initAccordions();
        this.initListeners();
    }

    /**
     * Initialises accordion object array property.
     */
    private initAccordions() {
        const accordionLabels = this.filterSidebar.querySelectorAll(
            '.gw-filter-sidebar__accordion'
        );

        const accordionContent = this.filterSidebar.querySelectorAll(
            '.gw-filter-sidebar__accordion-content'
        );

        for (let i = 0; i < accordionLabels.length; i++) {
            const multiToggle = accordionContent[i].querySelector(
                '.gw-button--multi-toggle'
            );

            let toggleObject = null as MultiToggle | null;
            if (multiToggle) {
                const inputs = multiToggle.querySelectorAll('.gw-form__input');
                const inputsArr = Array.from(inputs) as HTMLInputElement[];
                const defaultIndex = inputsArr.findIndex((i) => i.checked);

                toggleObject = {
                    element: multiToggle,
                    defaultIndex: defaultIndex,
                };
            }

            this.accordions.push({
                label: accordionLabels[i],
                content: accordionContent[i],
                multiToggle: toggleObject,
            });
        }
    }

    /**
     * Initialises all event listeners required for the sidebar.
     */
    private initListeners() {
        this.initSidebarPlacement();
        this.initResetButtons();
        this.initPaymentOptionToggle();
        this.initPaymentOptionInputChange();
        this.initSidebarOpen();
        this.initAccordionOpen();
        this.initAccordionNotifications();
    }

    private initSidebarPlacement() {
        this.handleSidebarPlacement();

        // Check to see if the screen has been resized - if so, ensure that sidebar is correctly positioned
        const resizeCallback = (): void => {
            this.handleSidebarPlacement();
        };

        window.setTimeout(() => {
            window.addEventListener('resize', resizeCallback);
        }, 500);

        // Similarly, on window scroll event, ensure that sidebar is correctly positioned.
        const windowScrollCallback = (): void => {
            this.handleSidebarPlacement();
        };

        window.addEventListener('scroll', windowScrollCallback);
    }

    private handleSidebarPlacement(): void {
        // Only do the following if larger than mobile
        if (window.innerWidth > 769) {
            const productFilterContent = document.querySelector(
                '#products-filter-content'
            ) as HTMLElement;

            // Only do the following when sticky header is being used
            if (this.siteHeader?.classList.contains('is-js-sticky')) {
                const stickyFooter = document.querySelector(
                    '.gw-layout__sticky--footer'
                );

                const topOffset =
                    this.siteHeader.getBoundingClientRect().height +
                    this.productSearchFilter.getBoundingClientRect().height -
                    1;

                if (stickyFooter) {
                    const footerHeight =
                        stickyFooter.getBoundingClientRect().height;
                    const heightRequired = topOffset + footerHeight;

                    productFilterContent.style.height = `calc(100vh - ${heightRequired}px)`;
                } else {
                    productFilterContent.style.height = `calc(100vh - ${topOffset}px)`;
                }

                if (productFilterContent) {
                    productFilterContent.style.top = `${topOffset}px`;
                }
            }
        }
    }

    /**
     * Initialises reset button functionality.
     */
    private initResetButtons() {
        const resetButtons = document.querySelectorAll(
            '.gw-filter-sidebar__button--reset'
        );
        resetButtons.forEach((r) => {
            r.addEventListener('click', () => {
                if (this.paymentOptionToggles.length > 0) {
                    this.togglePaymentOption(this.paymentOptionToggles[0]);
                    this.toggleProductCards(0);
                }
                this.accordions.forEach((accordion) => {
                    this.resetAccordion(accordion);
                    this.resetNotifications(accordion.label);
                });
                const url = window.location.href;
                window.location.href = url.split('?')[0];
            });
        });
    }

    /**
     * Resets the supplied accordion element.
     * @param accordion The accordion element to reset.
     */
    private resetAccordion(accordion: Accordion) {
        const accordionFields = accordion.content.querySelectorAll(
            '.gw-form__input, .gw-form__select'
        );
        accordionFields.forEach((field) => {
            if (field instanceof HTMLInputElement) {
                field.checked = false;
            } else if (field instanceof HTMLSelectElement) {
                this.resetSelectInput(field);
            }
        });

        if (accordion.multiToggle) {
            this.resetMultiToggle(
                accordion.multiToggle.element,
                accordion.multiToggle.defaultIndex
            );
        }
    }

    /**
     * Resets a multi-toggle fieldset to its default selection.
     * @param toggle
     * @param defaultIndex
     */
    private resetMultiToggle(toggle: Element, defaultIndex: number) {
        const toggleInputs = toggle.querySelectorAll(
            '.gw-form__input'
        ) as NodeListOf<HTMLInputElement>;
        toggleInputs.forEach((input, index) => {
            if (index === defaultIndex) {
                this.selectMultiToggleInput(input);
            } else {
                this.unselectMultiToggleInput(input);
            }
        });
    }

    /**
     * Selects a given option inside a multi-toggle field.
     * @param toggleInput The input element to check.
     */
    private selectMultiToggleInput(toggleInput: HTMLInputElement) {
        toggleInput.checked = true;
        toggleInput.closest('.gw-form__field')!.classList.add('is-selected');
    }

    /**
     * Unchecks a given option inside a multi-toggle field.
     * @param toggleInput The input element to uncheck.
     */
    private unselectMultiToggleInput(toggleInput: HTMLInputElement) {
        toggleInput.checked = false;
        toggleInput.closest('.gw-form__field')!.classList.remove('is-selected');
    }

    /**
     * Resets all notifications within a given element
     * @param container The element to reset all notifications within.
     */
    private resetNotifications(container: Element) {
        const notifications = container.querySelectorAll(
            '.gw-filter-sidebar__accordion__notification'
        );
        notifications.forEach((n) => {
            n.innerHTML = '0';
            n.classList.add('is-hidden');
        });
    }

    /**
     * Initialises payment option toggle state
     */
    private initPaymentOptionToggle() {
        const cashDropdowns = this.filterSidebar.querySelector(
            '#filter-sidebar-content-cash'
        );
        const financeDropdowns = this.filterSidebar.querySelector(
            '#filter-sidebar-content-finance'
        );
        const url = window.location.href;
        let queryUrl = url.split('?')[1];
        if (!queryUrl) queryUrl = '';
        if (
            queryUrl.includes('MonthlyPayment') ||
            queryUrl.includes('PriceBy=monthlypayment')
        ) {
            this.toggleProductCards(1);
            if (!this.financeBtnToggle.classList.contains('is-selected')) {
                this.paymentOptionToggles.forEach((t, index) => {
                    this.togglePaymentOption(t);
                });
            }

            if (financeDropdowns?.classList.contains('is-hidden')) {
                financeDropdowns.classList.remove('is-hidden');
                cashDropdowns?.classList.add('is-hidden');
            }
        }

        this.paymentOptionToggles.forEach((t, index) => {
            t.addEventListener('click', () => {
                if (!t.classList.contains('is-selected')) {
                    this.togglePaymentOption(t);
                    this.toggleProductCards(index);
                }
            });
        });
    }

    /**
     * Toggles payment option to either Cash or Finance
     */
    private togglePaymentOption(toggle: Element) {
        this.paymentOptionToggles.forEach((t) =>
            t.classList.remove('is-selected')
        );
        toggle.classList.add('is-selected');
        this.paymentFilters.forEach((p) => p.classList.toggle('is-hidden'));
    }

    /**
     * Toggles Cash/Finance info displayed on search result product cards.
     */
    private toggleProductCards(index: number) {
        const productCards = document.querySelectorAll('.gw-product-card');
        productCards.forEach((p) => {
            const pricingInfo = p.querySelectorAll('.gw-product-card__pricing');
            if (pricingInfo.length > 1) {
                pricingInfo.forEach((infoElement) => {
                    infoElement.classList.add('is-hidden');
                });
                pricingInfo[index].classList.remove('is-hidden');
            }
        });
    }

    /**
     * Ensures only one payment option (Cash or Finance) can have selected Min/Max values at any given time.
     * When one of the payment options is edited, resets the values of the other to initial 'Any' value.
     */
    private initPaymentOptionInputChange() {
        const paymentFilterInputs = this.filterSidebar.querySelectorAll(
            '.gw-filter-sidebar__payment-filter .gw-form__select'
        );

        this.paymentOptionToggles.forEach((t) => {
            t.addEventListener('click', () => {
                paymentFilterInputs.forEach((p) => {
                    const filterToReset = this.filterSidebar.querySelector(
                        '.gw-filter-sidebar__payment-filter.is-hidden'
                    ) as Element;
                    const selectInputs = filterToReset.querySelectorAll(
                        '.gw-form__select'
                    ) as NodeListOf<HTMLSelectElement>;
                    selectInputs.forEach((input) => {
                        this.resetSelectInput(input);
                    });
                });

                const pricingAccordion = this.filterSidebar.querySelector(
                    '.gw-filter-sidebar__accordion-pricing'
                );
                const accordionContentVal =
                    pricingAccordion?.getAttribute('data-accordion');
                const accordionBtn = this.filterSidebar.querySelector(
                    '#accordion-' + accordionContentVal
                );

                if (accordionBtn) {
                    this.resetNotifications(accordionBtn);
                }
            });
        });
    }

    /**
     * Resets a select input box
     * @param input Input to reset.
     */
    private resetSelectInput(input: HTMLSelectElement) {
        input.selectedIndex = 0;
        Array.prototype.forEach.call(input.options, (o) => {
            o.style.display = 'block';
        });
    }

    /**
     * Handles default state of sidebar open/close button.
     */
    private initSidebarOpen() {
        if (window.innerWidth < 769) {
            this.toggleSidebarDisplay();
        }
        if (this.toggleButtonOpen) {
            this.toggleButtonOpen.addEventListener('click', (e) => {
                e.preventDefault();
                this.toggleSidebarDisplay();
            });
        }
    }

    /**
     * Toggles sidebar open/close.
     */
    private toggleSidebarDisplay() {
        this.toggleButtonOpen.classList.toggle('open');
        this.filterSidebar.classList.toggle('show');
    }

    /**
     * Handles when accordions are clicked.
     */
    private initAccordionOpen() {
        this.accordions.forEach((accordion) => {
            accordion.label.addEventListener('keydown', (e) => {
                const keyboardEvent = e as KeyboardEvent;
                if (
                    keyboardEvent.code == 'Enter' &&
                    !accordion.label.classList.contains('is-disabled')
                ) {
                    accordion.label.classList.toggle('active');
                    accordion.content.classList.toggle('show');
                }
            });
            accordion.label.addEventListener('click', () => {
                if (!accordion.label.classList.contains('is-disabled')) {
                    accordion.label.classList.toggle('active');
                    accordion.content.classList.toggle('show');
                }
            });
        });
    }

    /**
     * Injects notification elements into accordion labels.
     */
    private initAccordionNotifications() {
        this.accordions.forEach((accordion, index) => {
            const notification = document.createElement('span');
            notification.classList.add(
                'gw-filter-sidebar__accordion__notification'
            );
            const inputs = accordion.content.querySelectorAll(
                '.gw-form__input[type="checkbox"], .gw-form__select'
            );

            this.updateNotificationValue(notification, inputs);
            const accordionToInsert = this.accordions[index].label;
            const insertBeforeIcon =
                accordionToInsert.querySelector('.gw-icon');
            accordionToInsert.insertBefore(notification, insertBeforeIcon);

            inputs.forEach((i) => {
                i.addEventListener('change', () =>
                    this.updateNotificationValue(notification, inputs)
                );
            });
        });
    }

    /**
     * Sets value of notification
     * @param container The container for the notification
     * @param inputs The inputs inside the container
     */
    private updateNotificationValue(
        notification: Element,
        inputs: NodeListOf<Element>
    ) {
        let value = 0;
        inputs.forEach((i) => {
            if (i instanceof HTMLInputElement && i.checked) {
                value++;
            } else if (i instanceof HTMLSelectElement && i.selectedIndex != 0) {
                value++;
            }
        });
        notification.innerHTML = value.toString();

        if (value > 0) {
            notification.classList.remove('is-hidden');
        } else {
            notification.classList.add('is-hidden');
        }
    }
}
