export class HomepageBanner {
    private desktopOverlay: HTMLElement | null;
    private mobileOverlay: HTMLElement | null;

    /**
     * Creates instance for multi-franchise campaign banner search.
     * @param desktopOverlay The campaign banner search form for desktop view.
     * @param mobileOverlay The campaign banner search form for mobile view.
     */
    constructor(desktopOverlay: HTMLElement, mobileOverlay: HTMLElement) {
        this.desktopOverlay = desktopOverlay;
        this.mobileOverlay = mobileOverlay;

        this.init();
    }

    /**
     * Checks if campaign banner exists on page and creates an instance of it if true.
     * @returns {instance} The campaign banner on the page (if it exists).
     */
    public static start(): any {
        const desktopOverlay = document.querySelector(
            '.gw-banner__overlay--mf-search.is-displayed-on-large'
        ) as HTMLElement;

        const mobileOverlay = document.querySelector(
            '.gw-banner__overlay--mf-search.is-hidden-on-large'
        ) as HTMLElement;

        if (desktopOverlay && mobileOverlay) {
            const instance = new HomepageBanner(desktopOverlay, mobileOverlay);
            return instance;
        }
    }

    /**
     * Initialises methods for handling specific elements and events.
     */
    private init(): void {
        this.handlePriceToggle();
    }

    /**
     * Handles when the main banner toggle is switched between Price and Monthly Cost.
     */
    private handlePriceToggle(): void {
        const desktopToggleBtns = this.desktopOverlay?.querySelector(
            '.gw-button--multi-toggle'
        );
        const mobileToggleBtns = this.mobileOverlay?.querySelector(
            '.gw-button--multi-toggle'
        );

        if (desktopToggleBtns && mobileToggleBtns) {
            // Find the buttons for both the desktop and mobile overlays.
            const desktopPriceBtn = desktopToggleBtns?.querySelector(
                '#banner-button-cash'
            );
            const desktopFinanceBtn = desktopToggleBtns?.querySelector(
                '#banner-button-finance'
            );
            const mobilePriceBtn = mobileToggleBtns?.querySelector(
                '#banner-button-cash'
            );
            const mobileFinanceBtn = mobileToggleBtns?.querySelector(
                '#banner-button-finance'
            );

            // Find the panels for both the desktop and mobile overlays.
            const desktopPricePanel = this.desktopOverlay?.querySelector(
                '#banner-minmax-price'
            );
            const desktopFinancePanel = this.desktopOverlay?.querySelector(
                '#banner-minmax-monthly-payment'
            );
            const mobilePricePanel = this.mobileOverlay?.querySelector(
                '#banner-minmax-price'
            );
            const mobileFinancePanel = this.mobileOverlay?.querySelector(
                '#banner-minmax-monthly-payment'
            );

            const mobileFinanceDropdowns =
                mobileFinancePanel?.querySelectorAll('.gw-form__select');
            const desktopFinanceDropdowns =
                desktopFinancePanel?.querySelectorAll('.gw-form__select');
            const mobilePriceDropdowns =
                mobilePricePanel?.querySelectorAll('.gw-form__select');
            const desktopPriceDropdowns =
                desktopPricePanel?.querySelectorAll('.gw-form__select');

            desktopToggleBtns.addEventListener('click', (e) => {
                // Check to see if the desktop price button has been selected.
                if (desktopPriceBtn?.classList.contains('is-selected')) {
                    // Check to see if the mobile price button is also selected.
                    if (!mobilePriceBtn?.classList.contains('is-selected')) {
                        mobilePriceBtn?.classList.add('is-selected');
                        mobileFinanceBtn?.classList.remove('is-selected');
                        mobilePricePanel?.classList.remove('is-hidden');
                        mobileFinancePanel?.classList.add('is-hidden');
                    }
                    if (desktopPricePanel?.classList.contains('is-hidden')) {
                        desktopPricePanel?.classList.remove('is-hidden');
                        desktopFinancePanel?.classList.add('is-hidden');
                    }

                    desktopFinanceDropdowns?.forEach(
                        (desktopFinanceDropdown) => {
                            if (
                                desktopFinanceDropdown instanceof
                                HTMLSelectElement
                            ) {
                                this.resetDropdowns(desktopFinanceDropdown);
                            }
                        }
                    );

                    mobileFinanceDropdowns?.forEach((mobileFinanceDropdown) => {
                        if (
                            mobileFinanceDropdown instanceof HTMLSelectElement
                        ) {
                            this.resetDropdowns(mobileFinanceDropdown);
                        }
                    });
                }

                if (desktopFinanceBtn?.classList.contains('is-selected')) {
                    if (!mobileFinanceBtn?.classList.contains('is-selected')) {
                        mobileFinanceBtn?.classList.add('is-selected');
                        mobilePriceBtn?.classList.remove('is-selected');
                        mobileFinancePanel?.classList.remove('is-hidden');
                        mobilePricePanel?.classList.add('is-hidden');
                    }
                    if (desktopFinancePanel?.classList.contains('is-hidden')) {
                        desktopFinancePanel?.classList.remove('is-hidden');
                        desktopPricePanel?.classList.add('is-hidden');
                    }

                    desktopPriceDropdowns?.forEach((desktopPriceDropdown) => {
                        if (desktopPriceDropdown instanceof HTMLSelectElement) {
                            this.resetDropdowns(desktopPriceDropdown);
                        }
                    });

                    mobilePriceDropdowns?.forEach((mobilePriceDropdown) => {
                        if (mobilePriceDropdown instanceof HTMLSelectElement) {
                            this.resetDropdowns(mobilePriceDropdown);
                        }
                    });
                }
            });

            mobileToggleBtns.addEventListener('click', (e) => {
                // Check to see if the desktop price button has been selected.
                if (mobilePriceBtn?.classList.contains('is-selected')) {
                    // Check to see if the mobile price button is also selected.
                    if (!desktopPriceBtn?.classList.contains('is-selected')) {
                        desktopPriceBtn?.classList.add('is-selected');
                        desktopFinanceBtn?.classList.remove('is-selected');
                        desktopPricePanel?.classList.remove('is-hidden');
                        desktopFinancePanel?.classList.add('is-hidden');
                    }
                    if (mobilePricePanel?.classList.contains('is-hidden')) {
                        mobilePricePanel?.classList.remove('is-hidden');
                        mobileFinancePanel?.classList.add('is-hidden');
                    }

                    desktopFinanceDropdowns?.forEach(
                        (desktopFinanceDropdown) => {
                            if (
                                desktopFinanceDropdown instanceof
                                HTMLSelectElement
                            ) {
                                this.resetDropdowns(desktopFinanceDropdown);
                            }
                        }
                    );

                    mobileFinanceDropdowns?.forEach((mobileFinanceDropdown) => {
                        if (
                            mobileFinanceDropdown instanceof HTMLSelectElement
                        ) {
                            this.resetDropdowns(mobileFinanceDropdown);
                        }
                    });
                }

                if (mobileFinanceBtn?.classList.contains('is-selected')) {
                    if (!desktopFinanceBtn?.classList.contains('is-selected')) {
                        desktopFinanceBtn?.classList.add('is-selected');
                        desktopPriceBtn?.classList.remove('is-selected');
                        desktopFinancePanel?.classList.remove('is-hidden');
                        desktopPricePanel?.classList.add('is-hidden');
                    }
                    if (mobileFinancePanel?.classList.contains('is-hidden')) {
                        mobileFinancePanel?.classList.remove('is-hidden');
                        mobilePricePanel?.classList.add('is-hidden');
                    }

                    desktopPriceDropdowns?.forEach((desktopPriceDropdown) => {
                        if (desktopPriceDropdown instanceof HTMLSelectElement) {
                            this.resetDropdowns(desktopPriceDropdown);
                        }
                    });

                    mobilePriceDropdowns?.forEach((mobilePriceDropdown) => {
                        if (mobilePriceDropdown instanceof HTMLSelectElement) {
                            this.resetDropdowns(mobilePriceDropdown);
                        }
                    });
                }
            });
        }
    }

    private resetDropdowns(dropdown: HTMLSelectElement) {
        dropdown.selectedIndex = 0;

        Array.prototype.forEach.call(dropdown.options, (o) => {
            o.style.display = 'block';
        });
    }
}
