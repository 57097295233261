// Import core/platform UI components.
import { HomepageBanner } from './components/homepage-banner';
import { Accordion } from './components/accordion';
import { BranchRotator } from './components/branch-rotator';
import { CookieConsent } from './components/cookie-consent';
import { CustomCheckbox } from './components/custom-checkbox';
import { FinanceOptions } from './components/finance-options';
import { FormAjaxSubmit } from './components/form-ajax-submit';
import { FormValidate } from './components/form-validate';
import { Modal } from './components/modal';
import { Navigation } from './components/navigation';
import { PriceGrid } from './components/price-grid';
import { ProductCard } from './components/product-card';
import { QuickView } from './components/quick-view';
import { RangeInput } from './components/range-input';
import { SearchFilters } from './components/search-filters';
import { Share } from './components/share';
import { ShowHide } from './components/show-hide';
import { Slider } from './components/slider';
import { StickyPosition } from './components/sticky-position';
import { StockFilter } from './components/stock-filter';
import { TabbedBanner } from './components/tabbed-banner'; // TODO: Is there a reason why this isn't used?
import { TabbedSlider } from './components/tabbed-slider';
import { Tabs } from './components/tabs';
import { VideoPlayer } from './components/video-player';
import { FormMulti } from './components/form-multi';
import { FilterSidebar } from './components/filter-sidebar';
import { FormMinmax } from './components/form-minmax';

// Import theme-specific components.
import { gwUIinitTheme } from './ui-init-theme';

// TODO: This is a TEMP fix for redirecting old DW forms.
import { triggerInterceptOldForms } from './components/intercept-old-forms';
import { MultiToggle } from './components/multi-toggle';

// Instantiate all UI component modules, and do other DOM manipulation.
export const gwUIinit = (): void => {
    // TODO: consider removing post-css logical if browser usage stats show that most users are using a modern browser
    // 'postcss-logical' in conjunction with 'postcss-dir-pseudo-class' auto-generates [dir="ltr"] and [dir="rtl"] selectors in the compiled CSS. These act as fallbacks for older browsers that don't understand CSS logical properties. If <html dir="rtl"> has not been explicitly set, ensure that we programatically set default direction 'ltr' so that [dir="ltr"] CSS selector fires for older browsers:
    document.documentElement.dir = document.documentElement.dir || 'ltr';

    // Cookie consent. 'GWconfig.cookieConsentAllScripts = true' can be set in CMS footer scripts at Master level.
    const cookieConsent = new CookieConsent();
    // eslint-disable-next-line no-undef
    if (GWconfig.cookieConsentAllScripts) {
        cookieConsent.setCookies(true);
    } else {
        cookieConsent.setCookies(false);
    }

    // Disclosure components (show|hide, accordions, tabs).
    ShowHide.start();
    Accordion.start();
    // TabbedBanner.start();
    Tabs.start();

    // Finance options for stock detail page and the homepage banner for multi-franchise sites.
    HomepageBanner.start();
    FinanceOptions.start();

    // Forms.
    CustomCheckbox.start();
    FormAjaxSubmit.start();
    FormMinmax.start();
    FormMulti.start();
    FormValidate.start();
    RangeInput.start();

    // Filter sidebar for stock page. Depends on Forms being loaded first.
    FilterSidebar.start();

    // Modal.
    Modal.start();

    // Multi Toggle.
    MultiToggle.start();

    // Navigation.
    Navigation.start();

    // Price grids.
    PriceGrid.start();

    // Product cards.
    ProductCard.start();

    // Quick View for Finance Information
    QuickView.start();

    // Search results filters.
    SearchFilters.start();

    // Share API.
    Share.start();

    // Slider.
    Slider.start();

    // Branch rotator. This depends on Slider being instantiated first.
    BranchRotator.start();

    // Sticky (header|footer) positioning.
    StickyPosition.start();

    // Stock Filter
    StockFilter.start();

    // Tabbed Slider
    TabbedSlider.start();

    // Video player.
    VideoPlayer.start();

    // Dynamically import & instantiate theme-specific components.
    gwUIinitTheme();

    // TODO: This is a TEMP fix for redirecting old DW forms.
    triggerInterceptOldForms();
};
